import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/jubilaciones',
    name: 'Jubilaciones',
    component: () => import(/* webpackChunkName: "Sucesiones" */ '../views/services/Jubilaciones.vue'),
    meta: {
      title: 'Jubilaciones'
    }
  },
  {
    path: '/pensiones',
    name: 'Pensiones',
    component: () => import(/* webpackChunkName: "Sucesiones" */ '../views/services/Pensiones.vue'),
    meta: {
      title: 'Pensiones'
    }
  },
  {
    path: '/reclamos',
    name: 'Reclamos',
    component: () => import(/* webpackChunkName: "Sucesiones" */ '../views/services/Reclamos.vue'),
    meta: {
      title: 'Reclamos'
    }
  },
  {
    path: '/amparo-por-mora',
    name: 'Amparo',
    component: () => import(/* webpackChunkName: "Sucesiones" */ '../views/services/Amparo.vue'),
    meta: {
      title: 'Amparo'
    }
  },
  {
    path: '/seguro-de-vida',
    name: 'Seguro',
    component: () => import(/* webpackChunkName: "Sucesiones" */ '../views/services/Seguro.vue'),
    meta: {
      title: 'Seguro'
    }
  },
  {
    path: '/art-accidente',
    name: 'Art',
    component: () => import(/* webpackChunkName: "Sucesiones" */ '../views/services/Art.vue'),
    meta: {
      title: 'Art'
    }
  },
  {
    path: '/sucesiones',
    name: 'Sucesiones',
    component: () => import(/* webpackChunkName: "Sucesiones" */ '../views/services/Sucesiones.vue'),
    meta: {
      title: 'Sucesiones'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
