<template>
  <div>
    <div class="columns p-5" :class="bgSeparator">
      <div class="column is-12">
        <h1 class="has-text-centered letterSpacingShort is-size-2 ">
          <span class="fontWeightBlack letterSpacingLarge" :class="titleOneColor"> 
            <div class="typewriter is-hidden-mobile">
              <h1>"{{ titleOne }}" Nelson Mandela</h1>
            </div>
            <div class="is-hidden-tablet animate__slow animate__animated animate__pulse animate__delay-1s animate__infinite">
              <h1>"{{ titleOne }}" Nelson Mandela</h1>
            </div>
          </span>
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Separator',
  props: ['titleOne', 'titleOneColor',
          'titleTwo', 'titleTwoColor',
          'titleThree', 'titleThreeColor',
          'subtitleOne', 'subtitleTwo', 'subtitleColor',
          'bgSeparator']
}
</script>

<style>

/* DEMO-SPECIFIC STYLES */
.typewriter h1 {
  color: #fff;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 5.5s steps(230, end) infinite
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

</style>
