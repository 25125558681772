<!-- eslint-disable -->
<template>
  <footer id="contacto">
    <br><br><br>
    <div class="container-fluid">
    <!-- <p class="title is-2 pt-6 has-text-right pr-6 fontWeightBlack fontMoreBlack">¡Quedate en casa!</p>
    <div class="lineFooter bgColorPrimary mt-1" /> -->
      <div class="container pl-6 pr-6 pb-6">
        <div class="row">
          <div class="columns is-multiline pt-6 pb-6">
            <div class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen is-6-fullhd">
              <img src="@/assets/img/logoAnimado.gif" class="logoFooter mb-2">
              <p class="is-size-2-mobile is-size-1-tablet fontWeightBlack fontMoreBlack">Entrevistas On-line</p>
              <p class="title is-3">¡Quedate en casa!</p>
              <img src="@/assets/img/zoom.png" class="logosSocialFooter pt-2 mb-2">
              <img src="@/assets/img/skype.png" class="logosSocialFooter pt-2 mb-2">
              <img src="@/assets/img/facebooklive.png" class="logosSocialFooter pt-2 mb-2">
              <img src="@/assets/img/whatsapp.png" class="logosSocialFooter pt-2 mb-2">
            </div> 
            <div class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen is-6-fullhd pr-0 paddingFooterEspecial">
              <p class="title is-1 textAlignResponsive fontWeightBlack">
                Contacto
              </p>
              <div class="lineFooter2 bgColorTertiaryLight mt-1 mb-5" />
              <div class="lineFooter3 bgColorTertiaryLight mt-1 mb-5" />
                      <div class="textAlignResponsive">
                        <font-awesome-icon icon="home" class="colorTertiary is-inline-block subtitle1" />
                        <p class="is-inline-block subtitle1 ml-2 fontWeightBlack" >
                          Dirección
                        </p>
                        <p class="subtitle2 mt-2 is-hidden-mobile">
                          Calle 12 N°690 e/45 y 46 (CP.1900) - La Plata
                        </p>
                        <p class="subtitle2 mt-2 is-hidden-tablet">
                          Calle 12 N°690 e/45 y 46 (CP.1900) <br> La Plata
                        </p>
                        <br>

                        <font-awesome-icon icon="envelope" class="colorTertiary is-inline-block subtitle1" />
                        <p class="is-inline-block subtitle1 ml-2 fontWeightBlack" >
                          Correo electrónico
                        </p>
                        <p class="subtitle2 mt-2">
                          info@beronabogados.com
                        </p>
                        <br>

                        <font-awesome-icon icon="phone" class="colorTertiary is-inline-block subtitle1" />
                        <p class="is-inline-block subtitle1 ml-2 fontWeightBlack" >
                          Teléfonos
                        </p>
                        <p class="subtitle2 mt-2 is-hidden-mobile">
                          0810-345-2032 / 011-53526080/81
                          <br>
                          11-5144-6943
                        </p>
                        <p class="subtitle2 mt-2 is-hidden-tablet">
                          0810-345-2032
                          <br>
                          011-53526080/81
                          <br>
                          11-5144-6943
                        </p>                        
                        <br>

                        <a href="https://www.facebook.com/BERONABOGADOS" target="_blank">
                          <img src="@/assets/img/facebook.png" class="is-inline-block" style="width:36px">
                        </a>
                        
                        <a href="https://www.instagram.com/beronabogados/" target="_blank">
                        <img src="@/assets/img/instagram.png" class="is-inline-block" style="width:36px;margin-left:4px">
                        </a>
                        
                        <p class="is-inline-block subtitle1 ml-2 fontWeightBlack is-inline-block" >
                          Redes Sociales
                        </p>
                        <p class="subtitle2 mt-2">
                          /beronabogados
                        </p>
                        <br>   
                        
                      </div>

            </div>           
          </div>
        </div>
      </div>
    </div>
    <div class="bgColorPrimary p-6">
        <p class="has-text-centered subtitle is-3 has-text-white mb-5 typewriter fontSecondary">
          {{ brand.slogan }}
        </p>
        <p class="has-text-centered subtitle is-5 has-text-white mb-2">
          Berón Abogados
        </p>
        <br>
        <p class="has-text-centered subtitle is-7 has-text-white">
          Sitio web desarrollado por <a href="https://www.cmlaplata.com.ar" class="has-text-white">CM La Plata</a>
        </p>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapState('brand', ['brand'])
  }
}
</script>

<style>
/* DEMO-SPECIFIC STYLES */
.typewriter {
  color: #fff;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 5.5s steps(230, end) infinite
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

</style>