<template>
  <section>
    <b-carousel class="" :indicator="false">
      <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
        <section :class="`hero marginTopSlider pb-5 sliderHeightPadding bg-${carousel.background} is-bold`">
            <div class="hero-body container">
              <div>
                <h2 class="has-text-white is-size-2 fontWeightBold letterSpacingShort">
                  {{ param.text }}
                </h2>
                <h2 class="has-text-white titleBig fontWeightUltraBlack has-text-centered">
                  Servicio integral
                </h2>
                <h2 class="has-text-white titleBig fontWeightUltraBlack has-text-right-tablet">
                  docente
                </h2>
                <h2 class="has-text-white is-size-4 fontWeightBlack letterSpacingShort has-text-right mb-4 scale-in-center-slow paddingEspecialMobile">
                  Asesorate y enterate de todo lo que tenemos para ofrecerte
                </h2>
                <h2 class="has-text-white is-size-2 fontMoreBlack fontWeightBlack letterSpacingShort has-text-right fade-in paddingEspecialMobile">
                  Jubilate con nosotros!
                </h2>
                <div class="mt-3">
                  <h2 class="has-text-white is-size-2 fontMoreBlack fontWeightBlack letterSpacingShort has-text-centered mr-5 is-hidden-tablet fade-in">
                    Contactanos ahora
                  </h2>
                  <button
                  class="is-inline-block zIndex999 buttonSliderWhatsapp animate__animated animate__shakeX animate__infinite animate__delay-1s animate__slower"
                  onclick="window.location.href='https://api.whatsapp.com/send?phone=541151446943'">
                    <img src="@/assets/img/botonWhatsapp.jpg"
                      class="buttonMenuWhatsapp is-inline-block fade-in"
                      > 
                  </button>
                  <h2 class="has-text-white is-size-2 fontMoreBlack fontWeightBlack letterSpacingShort has-text-right mr-5 is-hidden-mobile fade-in">
                    Contactanos ahora
                  </h2>
                </div>
              </div>
            </div>
        </section>
      </b-carousel-item>
    </b-carousel>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'Slider',
    data () {
      return {
          param: '',
          progress: true,
          progressType: 'is-primary',
          carousels: [
              {
              background: 'img1'
              }
              // ,
              // {
              // background: 'img2'
              // }
          ]
      }
    },
    created () {
      this.catchParams()
      console.log(this.$route.query)
    },
    computed: {
      ...mapState('brand', ['brand'])
    },
    methods: {
      catchParams () {
        this.param = this.$route.query
    }
  }
}
</script>

<style lang="sass">

.bg-img1
    width: 100%
    background-size: cover
    background-repeat: no-repeat
    @media (min-width: 720px)
        background-image: url("../../assets/img/slider.jpg")
    @media (max-width: 720px)
        background-image: url("../../assets/img/sliderMobil.jpg")

    //   -webkit-background-size: cover
    // -moz-background-size: cover
    // -o-background-size: cover
    // background-size: cover!important
    // @media (min-width: 720px)
    //     background: url("../../assets/img/slider.jpg") no-repeat center center fixed
    // @media (max-width: 720px)
    //     background: url("../../assets/img/sliderMobil.jpg") no-repeat center center fixed

// .bg-img2
//     width: 100%
//     background-size: cover
//     @media (min-width: 720px)
//         background-image: url("../../assets/img/slider2.webp")
//     @media (max-width: 720px)
//         background-image: url("../../assets/img/slider.jpg")

</style>
