<template>
  <div class="counter mt-5 pt-5 fontWeightBlack fontNoSoBlack">
    <p class="counterTitle">Recomendaciones</p>
    <ICountUp
      class="colorPrimary fontWeightBlack fontMoreBlack"
      :delay="delay"
      :endVal="endVal"
      :options="options"
      @ready="onReady"
    />
  </div>
</template>

<script type="text/babel">
  import ICountUp from 'vue-countup-v2';
  export default {
    name: 'demo',
    components: {
      ICountUp
    },
    data() {
      return {
        delay: 500,
        endVal: 927,
        options: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
          prefix: '',
          suffix: '',
          duration: 20
        },
        
      };
    },
    methods: {
      onReady: function(instance, CountUp) {
        const that = this;
        instance.update(that.endVal + 220);
        console.log(CountUp)
      }
    }
  };
</script>
