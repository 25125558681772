var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"is-block width100 positionFixed zIndex999"},[_c('div',{staticClass:"columns bgColorWhite mb-0",attrs:{"id":"logoMenuFold"}},[_c('div',{staticClass:"column is-12 mb-0 pb-0"},[_c('img',{staticClass:"logoMobile is-hidden-tablet pb-2 pt-3",attrs:{"src":require("@/assets/img/logoLawFirm.png"),"alt":_vm.brand.name,"id":"imgMenuLogoMobileFold"}}),_c('img',{staticClass:"logo is-hidden-mobile p-3",attrs:{"src":require("@/assets/img/logoLawFirm.png"),"alt":_vm.brand.name,"id":"imgMenuLogoFold"}})])]),_c('b-navbar',{directives:[{name:"show",rawName:"v-show",value:(
         this.$route.name !== 'Sucesiones'
      && this.$route.name !== 'Jubilaciones'
      && this.$route.name !== 'Pensiones'
      && this.$route.name !== 'Seguro'
      && this.$route.name !== 'Art'
      && this.$route.name !== 'Reclamos'
      && this.$route.name !== 'Amparo'
      ),expression:"\n         this.$route.name !== 'Sucesiones'\n      && this.$route.name !== 'Jubilaciones'\n      && this.$route.name !== 'Pensiones'\n      && this.$route.name !== 'Seguro'\n      && this.$route.name !== 'Art'\n      && this.$route.name !== 'Reclamos'\n      && this.$route.name !== 'Amparo'\n      "}],staticClass:"paddingRightMenuResponsive bgColorPrimary",attrs:{"id":"menuFold","shadow":true},scopedSlots:_vm._u([{key:"end",fn:function(){return [_c('b-navbar-item',{staticClass:"has-text-left-tablet sizeMenuFont iconHover",attrs:{"href":"#inicio"}},[_c('font-awesome-icon',{staticClass:"mr-1 pb-05 iconHover",attrs:{"icon":['fa', 'home']}}),_vm._v(" Inicio ")],1),_c('b-navbar-item',{staticClass:"has-text-left-tablet sizeMenuFont iconHover",attrs:{"href":"#servicios"}},[_c('font-awesome-icon',{staticClass:"mr-1 pb-05 iconHover",attrs:{"icon":['fa', 'landmark']}}),_vm._v(" Servicios ")],1),_c('b-navbar-item',{staticClass:"has-text-left-tablet sizeMenuFont iconHover",attrs:{"href":"#clientes"}},[_c('font-awesome-icon',{staticClass:"mr-1 pb-05 iconHover",attrs:{"icon":['fa', 'user']}}),_vm._v(" Nuestros clientes ")],1),_c('b-navbar-item',{staticClass:"has-text-left-tablet sizeMenuFont mr-5 iconHover",attrs:{"href":"#contacto"}},[_c('font-awesome-icon',{staticClass:"mr-1 pb-05 iconHover",attrs:{"icon":['fa', 'envelope']}}),_vm._v(" Contactos ")],1)]},proxy:true}])}),_c('b-navbar',{directives:[{name:"show",rawName:"v-show",value:(
         this.$route.name === 'Sucesiones'
      || this.$route.name === 'Jubilaciones'
      || this.$route.name === 'Pensiones'
      || this.$route.name === 'Seguro'
      || this.$route.name === 'Art'
      || this.$route.name === 'Reclamos'
      || this.$route.name === 'Amparo'
      ),expression:"\n         this.$route.name === 'Sucesiones'\n      || this.$route.name === 'Jubilaciones'\n      || this.$route.name === 'Pensiones'\n      || this.$route.name === 'Seguro'\n      || this.$route.name === 'Art'\n      || this.$route.name === 'Reclamos'\n      || this.$route.name === 'Amparo'\n      "}],staticClass:"paddingRightMenuResponsive bgColorPrimary",attrs:{"id":"menuFold","shadow":true},scopedSlots:_vm._u([{key:"end",fn:function(){return [_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ name: 'Home' },"lass":"has-text-left-tablet sizeMenuFont iconHover has-text-white"}},[_c('font-awesome-icon',{staticClass:"mr-1 pb-05 iconHover",attrs:{"icon":['fa', 'home']}}),_vm._v(" Inicio ")],1),_c('b-navbar-item',{staticClass:"has-text-left-tablet sizeMenuFont iconHover",attrs:{"href":"#","tag":"router-link","to":{ path: '/#servicios' }}},[_c('font-awesome-icon',{staticClass:"mr-1 pb-05 iconHover",attrs:{"icon":['fa', 'landmark']}}),_vm._v(" Servicios ")],1),_c('b-navbar-item',{staticClass:"has-text-left-tablet sizeMenuFont iconHover",attrs:{"tag":"router-link","to":{ path: '/#clientes' }}},[_c('font-awesome-icon',{staticClass:"mr-1 pb-05 iconHover",attrs:{"icon":['fa', 'user']}}),_vm._v(" Nuestros clientes ")],1),_c('b-navbar-item',{staticClass:"has-text-left-tablet sizeMenuFont mr-5 iconHover",attrs:{"tag":"router-link","to":{ path: '/#contacto' }}},[_c('font-awesome-icon',{staticClass:"mr-1 pb-05 iconHover",attrs:{"icon":['fa', 'envelope']}}),_vm._v(" Contactos ")],1)]},proxy:true}])})],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }