export default {
    namespaced: true,
    state: {

        //Marca
        brand:{
            name:'Berón Abogados',
            slogan:'"... Siempre parece imposible hasta que se hace .."',
            email: 'info@beronabogados.com', // Para el servidor usar de email usar el password: 3610410543aA$q 
            emailUrl: 'beronabogados.com',
            phone: '11-5144-6943',

            urlWebForImgEmail: 'https://beronabogados.com.ar',
            whatsappUrl: 'https://wa.me/541151446943',
            urlIG:'https://www.instagram.com/beronabogados',
            urlFB:'https://www.facebook.com/beronabogados'
        },

    },

    mutations: {

    },

    actions: {

    },
    getters:{
        
    }
}