import Vue from 'vue'
import Vuex from 'vuex'
import brand from './modules/brand'

Vue.use(Vuex)

export default new Vuex.Store({

  state: { 
    serviceOpen: false
  },

  mutations: { },

  actions: {
    setServiceOpen ({state}) {
      state.serviceOpen ? state.serviceOpen = false : state.serviceOpen = true
    }
  },

  getters:{ },

  modules: {
    brand
  }
})
