import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Buefy
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import '@/assets/css/variables.sass'
import '@/assets/css/main.sass'
import '@/assets/css/animations.sass'
Vue.use(Buefy)

// Font awesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faHome, faAd, faForward, faClipboard, faCreditCard, faCheckCircle, faMousePointer, faEye, faLaptopHouse,
faPencilRuler, faBriefcase, faCopy, faFolderOpen, faHeartbeat, faSearchLocation, faUser, faLandmark, faShoppingCart, faPhone, faTrash, faAlignJustify, faArrowLeft, faEnvelope, faEdit, faArrowRight, faSearch, faPowerOff, faAddressBook, faExclamationTriangle, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add( faUserSecret, faHome, faAd, faForward, faClipboard, faCreditCard, faCheckCircle, faMousePointer, faEye, faLaptopHouse,
faPencilRuler, faBriefcase, faCopy, faFolderOpen, faHeartbeat, faSearchLocation, faShoppingCart, faUser, faLandmark, faPhone, faTrash, faAlignJustify, faArrowLeft, faEnvelope, faEdit, faArrowRight, faSearch, faPowerOff, faAddressBook, faExclamationTriangle, faFileInvoiceDollar  )
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Aos
import AOS from 'aos'
import 'aos/dist/aos.css'; // ou can also use <link> for styles
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 200, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 0, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

// Meta-data
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
