<template>
  <section>
    <a :href="brand.whatsappUrl" target="_blank" class="whatsapp" @mouseover="animateWhatsappImg">
      <img src="@/assets/img/whatsapp.webp" :class="animateImg">
      <p class="requestBudget fontWeightRegular">
        <font-awesome-icon icon="arrow-left" class="iconWhatsappBudgeter" />
        Contactanos
      </p>
    </a>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Whatsapp',
  data () {
    return {
      animateImg: 'none'
    }
  },
  computed: {
    ...mapState('brand', ['brand'])
  },
  methods: {
    animateWhatsappImg () {
      this.animateImg = 'rotateImgOnHover'
      setTimeout(function(){
        this.animateImg = 'none'
      }.bind(this), 1000)
    }
  }
}
</script>
