<template>
  <div>

      <Slider id="inicio"  style="z-index:0!important;position:relative;display:block"/>

     <SeparatorSection

      :titleOne="'... Siempre parece imposible hasta que se hace ...'" 
      :titleOneColor="'has-text-white fontSecondary'"
      :titleTwo="'Nelson Mandela'"
      :titleTwoColor="'has-text-white fontSecondary'"
      :bgSeparator="'bgColorPrimary hasShadow'"
      />

      <div
        class="container-fluid"
        style="line-height:0px!important">
        <img src="@/assets/img/imgSeparator.jpg" class="width100" >
      </div>

      <Services id="servicios"/>

      <div
        class="container-fluid "
        style="line-height:0px!important">
        <img src="@/assets/img/logoBeron.jpg" class="widthLogoSeparator mb-5" >
      </div>  
  <div id="clientes" ><br><br></div>
      <div
        class="container" 
        data-aos="fade-up">
        <h1
          class="titleBig2 text-dark fontWeightBlack fontMoreBlack mt-6 mb-3 pl-2 pr-2">
          ¡Cada vez son más quienes nos eligen!
        </h1>
        <div class="lineCounter bgColorTertiaryLight is-block mx-auto mb-3" />
        <NumberCounter class="is-inline-block"/>
        <NumberCounterTwo class="is-inline-block ml-2"/>
        <h1 class="text-center title is-1 mt-6 fontSecondary p-5">
          
        </h1>
      </div>
    
      <div
        class="container mb-7" >
      <b-carousel :pause-info="false" >
          <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
              <section :class="`hero is-medium is-${carousel.color}`">
                  <div class="has-text-centered minHeightSliderOpinions">
                      <h1 class="is-size-2-tablet is-size-3-mobile fontSecondary">{{carousel.text}}<br> {{carousel.name}}</h1>
                  </div>
              </section>
          </b-carousel-item>
      </b-carousel>
      </div>

      <iframe
   
       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6542.879130756027!2d-57.95845322393507!3d-34.92051132151449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a2e70fde4f65e7%3A0x74e480bd35766bb9!2sB%26A-BERONABOGADOS!5e0!3m2!1ses-419!2sar!4v1611951837340!5m2!1ses-419!2sar" width="100%" height="450" frameborder="0" style="border:0;line-height:0px!important;padding-bottom:0px!important;margin-bottom:0px!important" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>    
      <Footer id="contacto"/>
      <Whatsapp  />
  </div>
</template>

<script>
import Slider from '@/components/sections/Slider'
import SeparatorSection from '@/components/sections/SeparatorSection'
import Services from '@/components/sections/Services'
import NumberCounter from '@/components/sections/NumberCounterJs'
import NumberCounterTwo from '@/components/sections/NumberCounterJsTwo'
import Footer from '@/components/Footer'
import Whatsapp from '@/components/Whatsapp'
import { mapState } from 'vuex'
export default {
  name: 'Home',
  components: {
    Slider ,
    SeparatorSection,
    Services,
    NumberCounter,
    NumberCounterTwo,
    Footer,
    Whatsapp
  },
  data(){
    return {
        carousels: [
            { text: '"... Muy responsables y dando asesoramiento en todo momento cuando lo necesité ..."', name: 'Laura Z.', color: 'white' },
            { text: '"... Excelente atención profesional y calidez humana de todas las personas que conforman la oficina. La verdad muy recomendable en todos los aspectos humanos, responsables y muy profesionales. Los felicito ..."', name:'Guillermo DF.', color: 'white' },
            { text: '"... Responsabilidad, cumplimiento y rapidez en los trámites ..."', name: 'Vanesa P.', color: 'white' },
            { text: '"... Confíen en ellos, son honestos, rápidos, valen la pena ...."', name: 'Monica S.', color: 'white' },
            { text: '"... Realmente un trato de privilegio y una eficacia puesta al servicio del cliente. Altamente recomendables ..."', name: 'Rita L.M.', color: 'white' }
        ]
    }
  },
  mounted () {
    if (this.$route.hash === '#clientes') {
      setTimeout(function(){ window.location.href = "#clientes" }, 500);
      
    }
    if (this.$route.hash === '#contacto') {
      setTimeout(function(){ window.location.href = "#contacto" }, 500);
      
    }
    if (this.$route.hash === '#servicios') {
      setTimeout(function(){ window.location.href = "#servicios" }, 500);
      
    }
  },
  computed: {
    ...mapState(['serviceOpen'])
  },
}
</script>
