<template>
  <div id="app">
    <div class="is-block width100 positionFixed zIndex999">
        <div id="logoMenuFold" class="columns bgColorWhite mb-0">
            <div class="column is-12 mb-0 pb-0">
                <img
                    src="@/assets/img/logoLawFirm.png"
                    :alt="brand.name"
                    class="logoMobile is-hidden-tablet pb-2 pt-3"
                    id="imgMenuLogoMobileFold"
                >
                <img
                    src="@/assets/img/logoLawFirm.png"
                    :alt="brand.name"
                    class="logo is-hidden-mobile p-3"
                    id="imgMenuLogoFold"
                >
            </div>
        </div>
         <b-navbar
        id="menuFold" 
        :shadow="true"
        class="paddingRightMenuResponsive bgColorPrimary"
        v-show="
           this.$route.name !== 'Sucesiones'
        && this.$route.name !== 'Jubilaciones'
        && this.$route.name !== 'Pensiones'
        && this.$route.name !== 'Seguro'
        && this.$route.name !== 'Art'
        && this.$route.name !== 'Reclamos'
        && this.$route.name !== 'Amparo'
        ">
            <template #end>
                <b-navbar-item href="#inicio" class="has-text-left-tablet sizeMenuFont iconHover">
                    <font-awesome-icon class="mr-1 pb-05 iconHover" :icon="['fa', 'home']"  />  Inicio
                </b-navbar-item>
                <b-navbar-item href="#servicios" class="has-text-left-tablet sizeMenuFont iconHover">
                    <font-awesome-icon class="mr-1 pb-05 iconHover" :icon="['fa', 'landmark']"  />  Servicios
                </b-navbar-item>
                <b-navbar-item href="#clientes" class="has-text-left-tablet sizeMenuFont iconHover">
                    <font-awesome-icon class="mr-1 pb-05 iconHover" :icon="['fa', 'user']"  />  Nuestros clientes
                </b-navbar-item>
                <b-navbar-item href="#contacto" class="has-text-left-tablet sizeMenuFont mr-5 iconHover">
                    <font-awesome-icon class="mr-1 pb-05 iconHover" :icon="['fa', 'envelope']"  />  Contactos
                </b-navbar-item>
            </template>
        </b-navbar>       
        <b-navbar
        id="menuFold" 
        :shadow="true"
        class="paddingRightMenuResponsive bgColorPrimary"
        v-show="
           this.$route.name === 'Sucesiones'
        || this.$route.name === 'Jubilaciones'
        || this.$route.name === 'Pensiones'
        || this.$route.name === 'Seguro'
        || this.$route.name === 'Art'
        || this.$route.name === 'Reclamos'
        || this.$route.name === 'Amparo'
        ">
            <template #end>
                <b-navbar-item tag="router-link" :to="{ name: 'Home' }" lass="has-text-left-tablet sizeMenuFont iconHover has-text-white">
                    <font-awesome-icon class="mr-1 pb-05 iconHover" :icon="['fa', 'home']"  />  Inicio
                </b-navbar-item>
                <b-navbar-item href="#" tag="router-link" :to="{ path: '/#servicios' }" class="has-text-left-tablet sizeMenuFont iconHover">
                    <font-awesome-icon class="mr-1 pb-05 iconHover" :icon="['fa', 'landmark']"  />  Servicios
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ path: '/#clientes' }" class="has-text-left-tablet sizeMenuFont iconHover">
                    <font-awesome-icon class="mr-1 pb-05 iconHover" :icon="['fa', 'user']"  />  Nuestros clientes
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ path: '/#contacto' }" class="has-text-left-tablet sizeMenuFont mr-5 iconHover">
                    <font-awesome-icon class="mr-1 pb-05 iconHover" :icon="['fa', 'envelope']"  />  Contactos
                </b-navbar-item>
            </template>
        </b-navbar>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    metaInfo() {
        return {
            title: "Beron Abogados"
        }
    },
    mounted () {
        this.checkRoute()
    },
    methods: {
        checkRoute () {
            console.log('asd')
            console.log(this.$route.name)
        }
    },
    computed: {
        ...mapState('brand', ['brand'])
    }
}
// // JS Vainilla
// Menu animation
function menuFold() {
    document.body.scrollTop > 10 || document.documentElement.scrollTop > 0
        ? (
            (
                console.log('1: ' + document.body.scrollTop),
                document.getElementById("logoMenuFold").className = "columns bgColorWhite mb-0 fondoPropio fadeMenuOff",
                document.getElementById("imgMenuLogoFold").className = "is-hidden-mobile p-3 logoMenu",
                document.getElementById("imgMenuLogoMobileFold").className = "logoMobile is-hidden-tablet pb-2 pt-3 scale-out-center"
            )
        )
        : (
            (
                console.log('2: ' + document.documentElement.scrollTop),
                document.getElementById("logoMenuFold").className = "columns bgColorWhite mb-0 fondoPropio fadeMenu",
                document.getElementById("imgMenuLogoFold").className = "is-hidden-mobile p-3 logoMenuOut",
                document.getElementById("imgMenuLogoMobileFold").className = "logoMobileOpacity logoMobile is-hidden-tablet pb-2 pt-3 "
            )
        );
}
window.onscroll = function () {
    menuFold()
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
