<template>
  <div>
      <div
      class="container-fluid bgColorSoftGrey paddingServiceSection pt-2"
      data-aos="fade-up">   
        <p class="fontWeightBlack fontMoreBlack title is-1 mt-6 fontUpperCase has-text-left mb-2 marginServiceTitle">
          Servicios
        </p>   
        <div class="line bgColorWhite" />
        <br>
        <div class="container">
          <div class="columns is-gapless p-5 is-multiline">


            <!-- Service 1 -->
            <div class="column is-mobile-12 mt-6 cursorPointer" data-aos="zoom-in" :class="`is-4`">
              <router-link :to="{name: 'Jubilaciones'}">
                <div class="fondoIconos">
                  <img src="@/assets/img/services/jubilaciones.png">
                </div>
                <p class="fontWeightBlack fontNoSoBlack servicesFont colorTertiary mt-5 mb-0 fontUpperCase">
                  Jubilaciones
                </p>
                <p class="fontWeightBlack title is-5 pt-4 pl-4 lineHeight1" >
                  Jubilación Docente: digital IPS / DIEGEP / ANSES - Jubilación automática docente - Jubilación por incapacidad.
                </p>
                </router-link>
            </div>

            <!-- Service 2 -->
            <div class="column is-mobile-12 mt-6 cursorPointer" data-aos="zoom-in" :class="`is-4`">
              <router-link :to="{name: 'Pensiones'}">
                <div class="fondoIconos">
                  <img src="@/assets/img/services/ips.png">
                </div>
                <p class="fontWeightBlack fontNoSoBlack servicesFont colorTertiary mt-5 mb-0 fontUpperCase">
                  Pensiones
                </p>
                <p class="fontWeightBlack title is-5 pt-4 pl-4 lineHeight1" >
                  Pensión directa - Pensión derivada - Pensión Directa Automática Docente - Pensión Derivada Automática Docente.
                </p>
                </router-link>
            </div>

            <!-- Service 3 -->
            <div class="column is-mobile-12 mt-6 cursorPointer" data-aos="zoom-in" :class="`is-4`">
              <router-link :to="{name: 'Reclamos'}">
                <div class="fondoIconos">
                  <img src="@/assets/img/services/reclamos.png">
                </div>
                <p class="fontWeightBlack fontNoSoBlack servicesFont colorTertiary mt-5 mb-0 fontUpperCase">
                  RECLAMOS Y REAJUSTES
                </p>
                <p class="fontWeightBlack title is-5 pt-4 pl-4 lineHeight1" >
                  Recurso de revocatoria - Reclamos - Reajuste por cese definitivo - Reajuste por cierre de cómputos
                </p>
                </router-link>
            </div>

            <!-- Service 4 -->
            <div class="column is-mobile-12 mt-6 cursorPointer" data-aos="zoom-in" :class="`is-3`">
              <router-link :to="{name: 'Amparo'}">
                <div class="fondoIconos">
                  <img src="@/assets/img/services/amparo.png">
                </div>
                <p class="fontWeightBlack fontNoSoBlack servicesFont colorTertiary mt-5 mb-0 fontUpperCase">
                  Amparo por mora
                </p>
                <p class="fontWeightBlack title is-5 pt-4 pl-4 lineHeight1" >
                  DGCYE: Retribución especial - Código jubilatorio - Recursos -DIEGEP - IPS
                </p>
                </router-link>
            </div>

            <!-- Service 5 -->
            <div class="column is-mobile-12 mt-6 cursorPointer" data-aos="zoom-in" :class="`is-3`">
              <router-link :to="{name: 'Seguro'}">
                <div class="fondoIconos">
                  <img src="@/assets/img/services/seguro.png">
                </div>
                <p class="fontWeightBlack fontNoSoBlack servicesFont colorTertiary mt-5 mb-0 fontUpperCase">
                  Seguro de vida
                </p>
                <p class="fontWeightBlack title is-5 pt-4 pl-4 lineHeight1" >
                  Docente - ARBA - YPF - Min. de Seguridad - Auxiliares - Servicio Penitenciario de Bs. As. - Min. Salud - Niñez y Adolescencia
                </p>
                </router-link>
            </div>

            <!-- Service 6 -->
            <div class="column is-mobile-12 mt-6 cursorPointer" data-aos="zoom-in" :class="`is-3`">
              <router-link :to="{name: 'Art'}">
                <div class="fondoIconos">
                  <img src="@/assets/img/services/art.png">
                </div>
                <p class="fontWeightBlack fontNoSoBlack servicesFont colorTertiary mt-5 mb-0 fontUpperCase">
                  ART / DESPIDOS
                </p>
                <p class="fontWeightBlack title is-5 pt-4 pl-4 lineHeight1" >
                 Enfermedades profesionales: Disfonía - Várices - Hernias -  Accidente / in itinere - Despidos
                </p>
              </router-link>
            </div>

            <!-- Service 7 -->
            
            <div class="column is-mobile-12 mt-6 cursorPointer" data-aos="zoom-in" :class="`is-3`" >
              <router-link :to="{name: 'Sucesiones'}">
                <div class="fondoIconos">
                  <img src="@/assets/img/services/sucesiones.png">
                </div>
                <p class="fontWeightBlack fontNoSoBlack servicesFont colorTertiary mt-5 mb-0 fontUpperCase">
                  Sucesiones
                </p>
                <p class="fontWeightBlack title is-5 pt-4 pl-4 lineHeight1" >
                  Sucesión ab intestato - Sucesión testamentaria
                </p>
              </router-link>
            </div>
             
                                                            
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,
      service7: false
      }
  },
  computed: {
    ...mapState(['serviceOpen'])
  },
  methods: {
    ...mapActions(['setServiceOpen']),
    setService1 () {
      this.service1 ? this.service1 = false : this.service1 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    setService2 () {
      this.service2 ? this.service2 = false : this.service2 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    setService3 () {
      this.service3 ? this.service3 = false : this.service3 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    setService4 () {
      this.service4 ? this.service4 = false : this.service4 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    setService5 () {
      this.service5 ? this.service5 = false : this.service5 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0)
    },
    setService6 () {
      this.service6 ? this.service6 = false : this.service6 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    },
    setService7 () {
      this.service7 ? this.service7 = false : this.service7 = true
      this.setServiceOpen('test')
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>

</style>
